body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

*{
    max-width: 110%;
    max-height: 100%;
    overflow-x: hidden;
}
#aboutItem{
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    font-weight: lighter;
    position: relative;
    text-align: left;
}

html{
    padding-bottom: 15px;

}
.bg-dark {
    background-color: #212121;
}
p{
    font: 1.5rem "Helvetica",sans-serif;
    font-family: 'IBM Plex Sans', sans-serif;
    align-items: center;
    margin-right: 15px;
    margin-left: 15px;
    padding: 4px;
}
.visitButton{
    border-radius: 20px;
}
.visitButton:hover{
    -webkit-animation: pop 800ms linear infinite;
            animation: pop 800ms linear infinite;
}
@-webkit-keyframes pop {
    from {transform:scaleY(1.0);}
    to {transform:scaleY(0.97);}
}
@keyframes pop {
    from {transform:scaleY(1.0);}
    to {transform:scaleY(0.97);}
}
@-webkit-keyframes spin {
    from {transform:scaleY(0);}
    to {transform:scaleY(1.0);}
}
@keyframes spin {
    from {transform:scaleY(0);}
    to {transform:scaleY(1.0);}
}

